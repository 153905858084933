$primary: #ffb127 !default;
$primary-text-color: #222 !default;

$border-color: rgba(0, 0, 0, 0.125) !default;
$border-radius: 0.25rem !default;

$calendar-hover-color: #f8f9fa !default;

.calendar-container {
  position: absolute;

  @include media-breakpoint-down(sm) {
    position: relative;
    width: 100%;
    right: 0;
  }
  z-index: 9999;
  right: 45px;

}

.react-calendar {
  margin-top: 12px;
  border: 1px solid $border-color !important;
  border-radius: $border-radius !important;
  padding: 1rem;
  background-color: white;

  // Quick fix for double view showing previous and next month's dates in current month.
  &.react-calendar--doubleView {
    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__month-view__days {
          .react-calendar__month-view__days__day--neighboringMonth {
            opacity: 0 !important;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }
  }

  .react-calendar__navigation {
    display: flex;
    .react-calendar__navigation__label {
      @extend .btn;
      @extend .btn-outline-light;
      border: none;
      font-weight: 600;
      color: $primary-text-color !important;
      .react-calendar__navigation__label__divider {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
    .react-calendar__navigation__arrow {
      @extend .btn;
      @extend .btn-outline-light;
      border-color: $border-color !important;
      color: $primary-text-color !important;

      &:disabled {
        background-color: #f0f0f0 !important;
        cursor: not-allowed;
      }

      @include media-breakpoint-down(sm) {
        &.react-calendar__navigation__prev2-button,
        &.react-calendar__navigation__next2-button {
          display: none;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      .react-calendar__navigation__arrow {
        &.react-calendar__navigation__prev2-button,
        &.react-calendar__navigation__next-button {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &.react-calendar__navigation__prev-button,
        &.react-calendar__navigation__next2-button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: -1px;
        }
      }
    }
  }

  .react-calendar__viewContainer {
    display: flex;
    gap: 40px;

    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        margin-top: 8px;
        text-align: center;
        .react-calendar__month-view__weekdays__weekday {
          abbr {
            text-decoration: none;
            -webkit-text-decoration: none;
            font-weight: 600;
          }
        }
      }
      .react-calendar__month-view__days {
        max-width: 300px;
        margin-top: 4px;

        .react-calendar__month-view__days__day {
          border: none !important;
          outline: 1px solid $border-color !important;
          outline-offset: -0.5px;
          padding: 4px 0;
          text-align: center;
          background-color: white;
          &:disabled {
            background-color: #f0f0f0;
          }
          &:hover {
            background-color: $calendar-hover-color;
          }
          abbr {
            font-weight: normal;
          }
        }
        .react-calendar__tile--now {
          z-index: 2;
          outline-color: $primary !important;
          background-color: #e4e4e4;
        }
        .react-calendar__tile--active {
          z-index: 1;
          background-color: green;
          border: none !important;
          outline-color: black !important;
        }
        .react-calendar__tile--hasActive {
          background-color: lighten($primary, 10);
          outline-color: $primary !important;
          color: white;
        }
        .react-calendar__tile--range {
          &.react-calendar__tile--active {
            background-color: lighten($primary, 10);
            border: none !important;
            outline-color: lightgray !important;
          }
          &.react-calendar__tile--rangeStart,
          &.react-calendar__tile--rangeEnd {
            z-index: 1;
            background-color: $primary !important;
            border: none !important;
            outline-color: lightgray !important;
            color: white;
          }
        }
      }
    }
  }
}

