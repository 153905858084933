$absence-external-bg-color: #fffaf7;

.card-arrow-left {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 20px 40px 0;
  border-color:
          transparent #fff transparent
          transparent;
  float: left;
}
.card-arrow-right {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 0 40px 20px;
  border-color:
          transparent transparent
          transparent #fff;
  float: right;
}
.left-border-0 {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.right-border-0 {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.absence-external {
  .card {
    background-color: $absence-external-bg-color;
  }
  .card-arrow-left {
    border-color:
            transparent $absence-external-bg-color transparent
            transparent;
  }
  .card-arrow-right {
    border-color:
            transparent transparent
            transparent $absence-external-bg-color;
  }
}
