.icon-sort {
  @extend .fa-duotone;
  @extend .fa-solid;
  @extend .fa-sort;
}

.icon-sort-up {
  @extend .icon-sort;
  rotate: 180deg;
}

.icon-sort-down {
  @extend .icon-sort;
}

.icon-sort-none {
  @extend .icon-sort;
  --fa-primary-opacity: 0.4;
}
