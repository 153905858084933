.badge-group {
  .badge.badge-pill {
    border-radius: 0;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    &:first-child {
      padding-left: 0.6rem;
      border-bottom-left-radius: 10rem;
      border-top-left-radius: 10rem;
    }
    &:last-child {
      padding-right: 0.6rem;
      border-bottom-right-radius: 10rem;
      border-top-right-radius: 10rem;
    }
  }
}
