$map-size: var(--map-size);

.image-modal-container {
  --map-size: 500px;

  display: flex;
  flex-direction: row;
  height: 100%;

  .image-modal-image {
    flex-grow: 1;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .image-modal-map {
    div {
      width: $map-size;
    }
    width: $map-size;
    transition: width 0.5s ease-in-out;
    &.collapsed {
      width: 0;
    }
  }
}
