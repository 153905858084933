.react-calendar-heatmap .color-empty {
  fill: #E3EBF6;
}
.react-calendar-heatmap .color-scale-1 {
  fill: #eac4b9;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #f19d7b;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #f8773e;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #FF5000;
}
