@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

.react-grid-item {
  cursor: grab;
  &.react-draggable-dragging {
    cursor: grabbing;
  }
  .no-drag {
    cursor: auto;
  }
}
.react-grid-item.react-grid-placeholder {
  background: #a8c0db;
  border-radius: 5px;
}

.dashboard-planning-widget-content {
  overflow-y: auto;
  height: 100%;
  .dashboard-planning-widget-item {
    padding-top: 12px;
    padding-bottom: 12px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
