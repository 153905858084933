.react-datepicker-wrapper {
  input {
    @extend .form-control
  }
}

.react-datepicker {
  border-color: $border-color;

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next, {
    top: 8px;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #F9FBFD;
      border-color: $border-color;
    }
    .react-datepicker__month {
      .react-datepicker__quarter--selected {
        background-color: $primary;
      }
    }
  }
}

.react-datepicker__triangle {
  &::before {
    border-bottom-color: $border-color !important;
  }
  &::after {
    border-bottom-color: #F9FBFD !important;
  }
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  padding: 8px;
}
