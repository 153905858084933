.comments-container {
  .card-comment-container {
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 16px;
    }
    @media print {
      &:last-child {
        padding-bottom: 0;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 24px;
      display: block;
      width: 2px;
      content: "";
      z-index: -1;
      background-color: $border-color;
    }
    @media print {
      &:nth-last-child(2) {
        padding-bottom: 16px;
      }
      &:last-child {
        padding-top: 0;
        &::before {
          height: 0;
        }
      }
    }

    .event {
      @media print {
        break-inside: avoid;
      }
      display: flex;
      .event-icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-left: 5px;
        margin-right: 5px;
        background-color: white;
        border: $border-color solid 1px;
        border-radius: 20px;
      }
      .event-body {
        flex-grow: 1;
        margin-left: 8px;
        margin-right: 20px;
        padding-top: 8px;
      }
    }
  }
}
