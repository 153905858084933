$task-checkbox-color: #28a745;

.task-checkbox-container {
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: rgba($task-checkbox-color, 0.75);
    box-shadow: 0 0 0 0.2rem rgba($task-checkbox-color, 0.25);
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $task-checkbox-color;
    background-color: $task-checkbox-color;
  }
  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba($task-checkbox-color, 0.5);
  }
}

.separator {
  border-top: 1px solid #e9ecef;
  width: 99%;
  margin: 1rem 0;
  text-align: left;
}
