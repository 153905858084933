.smithsolar-logo {
  width: 100%;
}

.smithsolar-logo.animate .sidebar-logo-path {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  opacity: 1;
}
.smithsolar-logo.animate .top {
  animation-name: animate-smithsolar-top
}
@keyframes animate-smithsolar-top {
  0%, 40% {
    opacity: 1;
  }
  20% {
    opacity: 0.6;
  }
}

.smithsolar-logo.animate .top-slat {
  animation-name: animate-smithsolar-top-slat
}
@keyframes animate-smithsolar-top-slat {
  0%, 20%, 60% {
    transform: none;
    opacity: 1;
  }
  40% {
    transform: translateX(-175px);
    opacity: 0.6;
  }
}

.smithsolar-logo.animate .bottom-slat {
  animation-name: animate-smithsolar-bottom-slat
}
@keyframes animate-smithsolar-bottom-slat {
  0%, 40%, 80% {
    transform: none;
    opacity: 1;
  }
  60% {
    transform: translateX(-175px);
    opacity: 0.6;
  }
}

.smithsolar-logo.animate .bottom {
  animation-name: animate-smithsolar-bottom
}
@keyframes animate-smithsolar-bottom {
  0%, 60%, 100% {
    opacity: 1;
  }
  80% {
    opacity: 0.6;
  }
}
