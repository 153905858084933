$primary: #FF5000;

$active-color: $primary;

$border-color: #E3EBF6;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

$sidebar-width: 250px;
$sidebar-width-collapsed: 93px;

$sidebar-transition-speed: 0.3s;
$sidebar-transition-curve: cubic-bezier(.93,0,.22,1);
$sidebar-overlay-curve: ease;

@import "~easymde/dist/easymde.min.css";

@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

@import "~react-calendar-heatmap/dist/styles.css";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "~react-datepicker/dist/react-datepicker.css";

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

@import "absence";
@import "badge";
@import "calendar";
@import "call-info";
@import "comments";
@import "dashboard";
@import "datepicker";
@import "file-input";
@import "heatmap";
@import "images";
@import "logo";
@import "navbar";
@import "products";
@import "reminders";
@import "sort";
@import "table";
@import "tasks";

@page {
  size: auto;
}

.text-vertical {
  writing-mode: vertical-rl !important;
  text-orientation: mixed !important;
}

.card-comment {
  margin-bottom: 15px;
}

.detail-header-cell {
  text-align: right;
  width: 250px;
}

.detail-header-cell-sm {
  text-align: right;
  width: 250px;
}

.card-qrcode {
  @media print {
    display: block !important;
  }
}

.flex-print-row {
  @media print {
    flex-direction: row !important;
  }
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mb-0-p-last {
  p:last-child {
    margin-bottom: 0;
  }
}

.fa-stack-05x {
  @extend .fa-stack-1x;
  font-size: 0.5em;
}
.fa-stack-075x {
  @extend .fa-stack-1x;
  font-size: 0.65em;
}
