$product-card-image-size: 78px;

.product-card-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  .product-thumbnail-card {
    @extend .card;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: $product-card-image-size;
    margin-right: 8px;
    overflow: hidden;

    img {
      width: 100%;
      max-height: $product-card-image-size;
      object-fit: contain;
    }
    i {
      color: #95aac9;
      font-size: 1.5rem;
    }
  }
}
