.modal {
  tr.tr-sticky {
    th {
      @extend .th-sticky;
      top: 0 !important;
    }
  }
  .table .thead-light tr.tr-sticky {
    th {
      @extend .th-sticky;
      top: 0 !important;
    }
  }
}
